<template>
  <div id="account-confirmation">
    <section class="section head">
      <div class="container">
        <h1>Confirmed!</h1>
      </div>
    </section>

    <section class="section body">
      <div class="container">
        <h4>Thank you! Your account has been confirmed.</h4>

        <div id="login-container">
          <Login :dialog="true" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { validEmail } from "@/services/shared.js";
import { mapGetters } from "vuex";
import Login from "@/components/Login.vue";

export default {
  name: "AccountConfirmation",
  components: {
    Login
  },
  metaInfo: function() {
    return {
      title: " - Account Confirmed",
      meta: [
        {
          name: "description",
          content: "Thank you! Your account has been confirmed."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      showLogin: false,
      user: {
        email: null,
        code: null
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"])
  },
  methods: {
    confirm: function() {
      if (!validEmail(this.user.email)) {
        this.$toastr("error", "Invalid email");
      } else {
        this.$store.dispatch("auth/confirmUser", this.user).then(
          response => {
            this.$toastr("success", `Welcome ${response.username}`);
            this.$store.dispatch("release/recoverReleases");
            this.$router.push({ name: "home" });
          },
          error => {
            this.$toastr("error", error.response.data["error"]);
          }
        );
      }
    }
  }
};
</script>
<style lang="scss">
#account-confirmation {
  section.body {
    text-align: center;

    #login-container {
      #login.dialog {
        position: static;

        .md-card {
          background-color: transparent;
          box-shadow: none;
          margin: 0 auto;
          display: block;

          .arrow {
            display: none;
          }

          .md-card-actions .md-button:before {
            opacity: 1;
            background: #2d979d;
          }

          .md-card-content {
            .md-field {
              flex-direction: row;

              label {
                color: black;
              }

              &.md-has-password .md-button {
                top: 3px;
              }
            }
          }
        }
      }
    }

    form {
      margin-top: 30px;

      .md-field {
        flex-direction: column;

        .md-input {
          width: 300px;
        }
      }
    }
  }
}
</style>
