<template>
  <div id="login" :class="{ dialog: dialog == true, 'is-loading': loading }">
    <md-card>
      <div class="arrow"></div>

      <transition name="fade">
        <div
          class="loading md-layout md-alignment-center-center"
          v-if="loading"
        >
          <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
          <span>Loading</span>
        </div>
      </transition>

      <md-card-content>
        <md-field
          class="md-layout md-gutter"
          :class="{ 'md-invalid': errors.username != null }"
        >
          <label
            class="md-layout-item md-size-30 md-small-size-100"
            for="username"
            >Email*</label
          >
          <div class="md-layout-item md-size-70 md-small-size-100">
            <md-input name="username" id="username" v-model="user.username" />
            <span class="md-error" v-if="errors.username != null">
              {{ errors.username }}
            </span>
          </div>
        </md-field>

        <md-field
          class="md-layout md-gutter"
          :class="{ 'md-invalid': errors.password != null }"
          :md-toggle-password="false"
        >
          <label
            class="md-layout-item md-size-30 md-small-size-100"
            for="password"
            >Password*</label
          >
          <div class="md-layout-item md-size-70 md-small-size-100">
            <md-input
              type="password"
              name="password"
              id="login_password"
              v-model="user.password"
            />
            <span class="md-error" v-if="errors.password != null">
              {{ errors.password }}
            </span>
          </div>
        </md-field>
      </md-card-content>

      <md-card-actions>
        <a class="forgot-credentials" @click="accountRecovery">
          Forgot your password?
        </a>
        <md-button
          type="submit"
          :class="{ 'md-raised md-primary': dialog != true }"
          @click="validateUser"
        >
          Login
        </md-button>
      </md-card-actions>
    </md-card>

    <div class="links">
      <router-link :to="{ name: 'faq' }">FAQs</router-link>
      <a href="">eSynch®</a>
      <router-link :to="{ name: 'public-search' }">Public Search</router-link>
      <a href="">Register</a>
      <a href="">HFA</a>
      <a href="">Terms of use</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  props: ["authError", "dialog"],
  data: function() {
    return {
      loading: false,
      user: {
        username: null,
        password: null
      },
      errors: {
        username: null,
        password: null
      }
    };
  },
  methods: {
    accountRecovery: function() {
      this.$emit("loginSuccess");
      this.$router.push({ name: "account-recovery" });
    },
    validateUser: async function() {
      let valid = true;

      this.errors = {
        username: null,
        password: null
      };

      for (const key in this.user) {
        if (this.user[key] == "" || this.user[key] == null) {
          this.errors[key] = "Required";
          valid = false;
        }
      }

      if (valid) {
        this.loading = true;

        try {
          const response = await this.$store.dispatch("auth/login", this.user);
          await this.$store.dispatch("release/recoverReleases");
          this.$toastr("success", `Welcome ${response}`);
          this.$emit("loginSuccess");
          this.$router.push({ name: "home" });
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.reset
          ) {
            this.$router.push({ name: "account-change" });
          } else {
            this.$toastr("error", "Username/Password incorrect");
          }
        }

        this.loading = false;
      }
    }
  },
  mounted: function() {
    if (this.$route.params.authError) {
      this.$toastr("warning", "You're not authenticated.");
    }
  }
};
</script>

<style lang="scss">
#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 600px;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6d0019+0,e81b25+100 */
  background: rgb(109, 0, 25); /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(109, 0, 25, 1) 0%,
    rgba(232, 27, 37, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(109, 0, 25, 1) 0%,
    rgba(232, 27, 37, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(109, 0, 25, 1) 0%,
    rgba(232, 27, 37, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d0019', endColorstr='#e81b25',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .links {
    position: absolute;
    bottom: 20px;

    a {
      color: #fff;
      display: inline-block;
      margin: 0 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .close {
    display: none;
  }

  .md-card {
    width: 450px;

    img {
      width: 300px;
      display: block;
      margin: 0 auto 20px;
    }

    .md-card-content {
      .md-field {
        .md-layout-item {
          position: relative;
        }
        label {
          color: #000;
          text-align: right;
        }
        .md-input {
          width: 100%;
        }
        .md-error {
          text-transform: capitalize;
        }

        &.md-has-password {
          .md-button {
            @media (max-width: 1903px) {
              right: 20px;
            }
            @media (max-width: 1280px) {
              right: 12px;
            }
            @media (max-width: 960px) {
              right: 8px;
            }
            @media (max-width: 600px) {
              right: 4px;
            }
          }
        }
      }
    }
    .forgot-credentials {
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.dialog {
    position: absolute;
    top: 60px;
    right: 0;
    display: block;
    height: auto;
    min-height: auto;
    background: none;

    .md-card {
      color: #fff;
      background-color: $dark-blue;
      width: 390px;

      .loading {
        width: 100%;
        height: 100%;
        background-color: rgba(45, 119, 124, 0.95);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        flex-direction: column;

        span {
          margin-top: 10px;
          font-size: 1.4em;
        }
      }

      .arrow {
        position: absolute;
        top: -28px;
        right: 10px;
        width: 1px;
        height: 1px;
        border: 15px solid transparent;
        border-bottom-color: $dark-blue;
      }
      .close {
        display: none;
      }

      .md-card-header {
        display: none;
      }

      .md-card-content {
        padding: 30px 0 0;

        .md-field {
          margin: 0 0 20px 0;

          label {
            color: #fff;
          }

          &.md-has-password {
            .md-button {
              top: 20px;
            }
          }
          &.md-invalid {
            .md-error {
              color: #ff5f5f;
              right: 20px;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }
      }

      .md-card-actions {
        padding-right: 20px;
        padding-left: 20px;
        justify-content: space-between;
        align-items: flex-end;

        .md-button {
          color: #fff;
          padding: 0;
        }
      }

      .forgot-credentials {
        font-size: 0.9em;
        color: #fff;
        text-decoration: underline;
      }
    }

    .links {
      display: none;
    }

    @media (max-width: 600px) {
      top: 0;
      z-index: 99;

      .md-card {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .arrow {
          display: none;
        }
        .close {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;

          .md-icon {
            color: #fff;
          }
        }
        .forgot-credentials {
          position: static;
        }
        .md-card-content {
          padding: 0 10px;

          .md-field {
            label {
              text-align: left;
            }
            &.md-has-password {
              .md-button {
                top: 38px;
              }
            }
          }
        }
      }
    }

    &.is-loading {
      .md-card-content,
      .md-card-actions {
        filter: blur(2px);
      }
    }
  }
}
</style>
