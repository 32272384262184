const navLinks = {
  "get-license": {
    icon: "search",
    to: "new-release",
    text: "get a license",
    subtext: "Obtain new licenses<br>through HFA's Songfile"
  },
  "my-rpojects": {
    icon: "folder",
    to: "my-projects",
    text: "my projects",
    subtext: "Access your existing licenses<br>for review or reorder"
  },
  "my-cart": {
    icon: "shopping_cart",
    to: "my-cart",
    text: "my cart",
    subtext:
      "View the contents of your<br>shopping cart, including your<br>saved carts."
  },
  faq: {
    icon: "help",
    to: "faq",
    text: "faq",
    subtext: ""
  },
  profile: {
    icon: "account_circle",
    to: "my-profile",
    text: "my profile",
    subtext: ""
  }
};

const questions = [
  {
    question:
      "How do I create an account with HFA to collect mechanical royalties?",
    answer:
      "<p>If you are interested in HFA representing your publishing entity for U.S mechanical royalties, please see the below information regarding the two publishing accounts we offer. We have two kinds of accounts: affiliated and non-represented. By affiliating with HFA, publishers have access to a range of licensing, collection, distribution, royalty compliance and legal services to assist them in administrating their catalog. In addition to having HFA handle your licensing and collections needs, becoming an affiliate of HFA provides full access to:</p><ul><li>Royalty compliance services</li><li>Income tracking services</li><li>A dedicated agent for inquiry handling</li><li>New media licensing opportunities</li><li>Online song registration and updating tools</li><li>A variety of online reports and analytical tools</li></ul><p>Please note that you must administer your own publishing in order to create an account with HFA. If your compositions are already administered by a publisher, you will need to contact your publisher directly with any questions regarding mechanical royalties. To apply for affiliation, please complete the online general information form at <router-link to=\"{ name: 'hfa-affiliate' }\">www.harryfox.com/#/hfa-affiliate</router-link>. Once you have completed the form, you will be instructed to select the administration services that you would like HFA to provide on your behalf. You can then view our Publisher Affiliation Agreement and submit your application fee through PayPal. Please allow up to four weeks for processing. If you do not want to affiliate with HFA, but still want to register your catalog in our database, you can create an HFA Non-represented account. With this account, you can register your titles, access royalty reports, and see current licenses. You can create this account for free here: <router-link to=\"{ name: 'register-songs' }\">www.harryfox.com/#/register-songs</router-link></p>"
  },
  {
    question:
      "I already have a publisher account. How do I update my account information?",
    answer: "<< LINKS TO W9 DD FORMS >>"
  },
  {
    question:
      "I already have a publisher account. How do I register my songs? ",
    answer:
      "<p>You can follow the below instructions to register your songs using eSong: </p><p>1. Log into your HFA Account at <a href='https://portal.harryfox.com' target='_blank'>https://portal.harryfox.com</a></p><p>2. Under Navigation, click on &quot;Asset Management&quot;. In the Compositions section of the page, click &quot;eSong&quot;. </p><p>3. Enter your song information. If you are registering new works, you only need to enter the following information for each registration: </p><p>Song title <br />Writer's first and last name</p><p>4. Click &quot;Search&quot; </p><p>5. Next, you will be prompted to add your HFA publisher name and/or HFA P#, and Ownership share. There are also fields for ISRC and general song information. These are optional but highly recommended! </p><p>*Please note, you should not include the &quot;%&quot; symbol when submitting your Ownership Share. Simply enter your claim without the % sign. For example, a 100% claim would be entered as '100'. </p><p>Once submitted, you will be returned to the 'Song Submission History' tab, which displays a list of the request you have submitted through eSong, along with the status of each request. You can see the details of each request by clicking on the request number.</p><p>If you wish to register more than 50 songs, you can use our eSong Bulk application. To download our eSong Bulk template, click on the &ldquo;Submit Bulk File&rdquo; tab in eSong followed by &ldquo;New eSong Template.&rdquo;</p>"
  }
];

const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const isEmpty = value => {
  return typeof value == "undefined" || !value;
};

export { navLinks, questions, validEmail, isEmpty };
